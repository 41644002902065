import { Profile, User, UserManager } from 'oidc-client';

export interface OauthConfig {
    onSessionExpired: () => void;
    onSessionRenewed: (result: any /*jwt token*/) => void;
}

export interface MockUserManager extends UserManager {
    signinSilent: () => Promise<User>;
    clearStaleState: () => Promise<void>;
    signinRedirect: () => Promise<void>;
}

export interface MockUser {
    access_token: string;
    expires_in: number;
    profile: Partial<Profile>;
}

export interface ILoginInfo {
    accessToken: string;
    expiresInSeconds: number;
    idToken: IDToken;
    locale: 'en-GB' | 'de-DE';
    profile: IUserProfile;
}

export class LoginInfo implements ILoginInfo {
    locale: 'en-GB' | 'de-DE';
    idToken: IDToken;
    profile: IUserProfile;

    constructor(
        public accessToken: string,
        public expiresInSeconds: number,
        public userProfile: Profile | Partial<Profile>,
        locale?: string
    ) {
        this.accessToken = accessToken;
        this.expiresInSeconds = expiresInSeconds;
        this.idToken = {
            sub: userProfile.sub,
            azp: userProfile.azp,
            account: userProfile.acc,
            tenant: userProfile.azp,
            email: userProfile.email,
        };
        switch (locale) {
            case 'en-GB':
            case 'de-DE':
                this.locale = locale;
                break;
            default:
                this.locale = 'en-GB';
                break;
        }
        this.profile = new UserProfile(userProfile);
    }
}

export interface IDToken {
    sub: string | undefined;
    azp?: string;
    account: string;
    tenant?: string;
    email?: string;
}

export interface IUserProfile {
    azp?: string;
    email?: string;
    familyName?: string;
    givenName?: string;
    locale?: string;
    name?: string;
    sub: string | undefined;
    account: string;
    tenant: string;
    username: string;
}

export class UserProfile implements IUserProfile {
    account: string;
    azp?: string;
    email?: string;
    familyName?: string;
    givenName?: string;
    locale?: string;
    name?: string;
    sub: string | undefined;
    tenant: string;
    username: string;

    constructor(profile: Profile | Partial<Profile>) {
        this.azp = profile.azp;
        this.email = profile.email;
        this.familyName = profile.family_name;
        this.givenName = profile.given_name;
        this.locale = profile.locale;
        this.name = profile.name;
        this.sub = profile.sub;
        this.account = profile.account;
        this.tenant = profile.tenant;
        this.username = profile.username;
    }
}
