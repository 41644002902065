// This is the runtime configuration being used for development
// against a local instance of an `authorization-server`. Note
// that the redirect only works if you run the `webpack-dev-server`
// on http://localhost:8081.

export const localIntegrationConfig = {
    backend: {
        APP_REGISTRY: `${window.location.origin}/appregistry`,
        USERADMIN_SERVICE: `${window.location.origin}/usersettings`,
        USER_SETTINGS_SERVICE: `${window.location.origin}/usersettings`,
        WEBFLEET_REGISTRY_SERVICE: `http://localhost:9090`, // our local mock server
        ASSET_ADMINISTRATION: 'http://localhost:3000', // origin of local asset-administration-web (SPA parent)
    },
    homeRoute: `${window.location.origin}`,
    id: 'env.integration',
    login: {
        authority: `https://localhost:8443`,
        clientId: 'test-client',
        mockAuthorization: false, //true, //authorization server must be running
        preventRedirect: false,
        redirectUri: `${window.location.origin}/redirect.html`,
        silentRedirectUri: `${window.location.origin}/redirect.html`,
    },
    logoutUri: `https://localhost:8443/logout`,
};
