// This is the runtime configuration being used for production
// where authorization is done via the `authorization-server`.

export const productionConfig = {
    backend: {
        APP_REGISTRY: 'https://api.iam.ccp-prod.net/appregistry',
        AUTHENTICATION_SERVICE: 'https://auth.iam.rio.cloud/oauth',
        USERADMIN_SERVICE: 'https://api.iam.ccp-prod.net/api/useradmin',
        USER_SETTINGS_SERVICE: 'https://api.iam.ccp-prod.net/api/useradmin',
        WEBFLEET_REGISTRY_SERVICE: `https://api.webfleet-connector.rio.cloud`,
        ASSET_ADMINISTRATION: 'https://administration.assets.rio.cloud', // origin of SPA parent
    },
    homeRoute: 'https://start.rio.cloud',
    id: 'env.production',
    login: {
        authority: `https://auth.iam.rio.cloud`,
        clientId: '5112db8b-2c93-4a76-967b-5bdb87ef9611',
        mockAuthorization: false, //true,
        preventRedirect: false,
        redirectUri: `https://webfleet-registry-web.webfleet-connector.rio.cloud/redirect.html`,
        silentRedirectUri: `https://webfleet-registry-web.webfleet-connector.rio.cloud/redirect.html`,
    },
    logoutUri: `https://auth.iam.rio.cloud/logout`,
};
