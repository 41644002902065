import { Log, UserManager } from 'oidc-client';

export default async function handleLoginRedirect() {
    const runsInIframe = window && window.parent && window.parent !== window;

    if (runsInIframe) {
        // Silent redirect within an <iframe>
        Log.logger = console;
        Log.level = Log.INFO;

        // This will propagate the received information provided via
        // query parameters to the parent frame
        const userManager = new UserManager({});
        userManager.events.addSilentRenewError((e: Error) => {
            console.error(e);
        });
        await userManager.signinSilentCallback();
    } else {
        window.location.href = window.location.href.replace('/redirect.html', '/');
    }
}
