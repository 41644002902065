import { toNumber } from 'lodash/fp';

export class MileageValidator {
    static validateMileage(mileage: string): boolean {
        const numericMileage = toNumber(mileage);
        return (
            !isNaN(numericMileage) &&
            Number.isInteger(numericMileage) &&
            mileage.slice(-1) !== '.' &&
            numericMileage >= 0
        );
    }
}
