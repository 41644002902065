import React, { Component, createRef, Dispatch, RefObject } from 'react';
import { ClearableInput } from 'rio-uikit';
import { connect } from 'react-redux';
import { IntlProvider, FormattedMessage } from 'react-intl';
import { selectVin, selectRegistrationError, selectIsMileageValid } from '../reducers/registration.reducer';
import { mileageUpdatedAction, mileageValidationAction } from '../actions/device.actions';

// Import language configuration
import { DEFAULT_LOCALE } from '../../lang/lang';
import { getLanguageData, getLocale } from '../../lang/lang.selectors';
import { MileageValidator } from '../validators/mileage.validator';
import { DeviceRegistryProps, DeviceRegistryState } from '../../../utils/device-registry.interface';
import { FrameService } from '../services/frame.service';
import { IAction } from '../../../models/action.model';

export const EXPLAIN_TEXT_GROUP_ID = 'explain-text-group';
export const MILEAGE_FORMGROUP_ID = 'mileage-formgroup-id';

export const MILEAGE_VALIDATION_MSG_ID = 'registration.input.validation.error.mileage';
export const EXPLAIN_TEXT_MSG_ID = 'registration.input.explain.text';
export const MILEAGE_LABEL_MSG_ID = 'registration.input.label.mileage';

export class DeviceRegistry extends Component<DeviceRegistryProps, DeviceRegistryState> {
    private readonly modalBody: RefObject<any>;
    private readonly mileageRef: RefObject<any>;
    constructor(props: DeviceRegistryProps) {
        super(props);
        this.state = {
            mileage: '',
            height: '',
        };

        // see https://reactjs.org/docs/refs-and-the-dom.html
        this.modalBody = createRef();
        this.mileageRef = createRef();
        this.onMileageUpdated = this.onMileageUpdated.bind(this);
        this.reportSize = this.reportSize.bind(this);
    }

    reportSize() {
        const boundingBox = this.modalBody.current && this.modalBody.current.getBoundingClientRect();
        // height of the body is sent to the parent, so it can be resized within the modal
        const clientHeight = boundingBox && boundingBox.height;
        if (clientHeight !== this.state.height) {
            this.setState({ height: clientHeight });
            FrameService.sendHeightToParent(clientHeight);
        }
    }

    componentDidMount() {
        FrameService.registerMessageListener();
        FrameService.sendSpaReadyToParent();
        this.reportSize();
    }

    componentDidUpdate() {
        const { errorKey } = this.props;
        this.reportSize();
        if (errorKey) {
            // @ts-ignore
            this.mileageRef.current.focus();
        }
    }

    onMileageUpdated(newValue: string) {
        const isValid = MileageValidator.validateMileage(newValue);
        this.props.setMileageValid(isValid);
        if (isValid) {
            this.setState({ mileage: newValue });
            this.props.updateMileage(newValue);
        }
    }

    renderMileageInput() {
        const isMileageValid = this.props.isMileageValid;
        const formGroupStyle = isMileageValid ? { marginBottom: 0 } : { paddingBottom: 30, marginBottom: 0 };
        const formGroupClassName = `form-group ${!isMileageValid ? 'has-error has-feedback' : ''}`;
        return (
            <div id={MILEAGE_FORMGROUP_ID} style={formGroupStyle} className={formGroupClassName}>
                <label>
                    <FormattedMessage id={MILEAGE_LABEL_MSG_ID} />
                </label>
                <ClearableInput
                    value={this.state.mileage}
                    onChange={this.onMileageUpdated}
                    inputRef={this.mileageRef}
                />
                {!isMileageValid && (
                    <span className="help-block">
                        <span>
                            <FormattedMessage id={MILEAGE_VALIDATION_MSG_ID} />
                        </span>
                    </span>
                )}
            </div>
        );
    }

    renderExplainText() {
        return (
            <div id={EXPLAIN_TEXT_GROUP_ID} className="padding-bottom-25">
                <span>
                    <FormattedMessage id={EXPLAIN_TEXT_MSG_ID} values={{ vin: <b>{this.props.vin}</b> }} />
                </span>
            </div>
        );
    }

    renderErrorMessage() {
        return (
            this.props.errorKey && (
                <div className="alert alert-danger margin-top-25 margin-bottom-0">
                    <span>
                        <FormattedMessage id={this.props.errorKey} />
                    </span>
                </div>
            )
        );
    }

    render() {
        return (
            <div ref={this.modalBody}>
                {this.renderExplainText()}
                {this.renderMileageInput()}
                {this.renderErrorMessage()}
            </div>
        );
    }
}

export const mapDispatchToProps = (dispatch: Dispatch<IAction>) => ({
    updateMileage: (mileage: string) => dispatch(mileageUpdatedAction(mileage)),
    setMileageValid: (valid: boolean) => dispatch(mileageValidationAction(valid)),
});

export const mapStateToProps = (state: any) => {
    return {
        vin: selectVin(state),
        isMileageValid: selectIsMileageValid(state),
        errorKey: selectRegistrationError(state),
        languageData: getLanguageData(state),
        userLocale: getLocale(state),
    };
};

export const AppContainer = (props: any) => {
    const { languageData, userLocale: locale } = props;
    return (
        <IntlProvider locale={locale} messages={languageData} defaultLocale={DEFAULT_LOCALE}>
            <DeviceRegistry {...props} />
        </IntlProvider>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(AppContainer);
