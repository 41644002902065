import { store } from '../../../configuration/store';
import { HttpService } from './http.service';
import { deviceRegistrationFailedAction, resetErrorKeyAction } from '../actions/device.actions';
import { selectRegistrationError } from '../reducers/registration.reducer';
import {
    DEVICE_REGISTRATIONS_URL,
    REGISTRATION_FAIL_EMPTY_RESULT,
    REGISTRATION_FAIL_UNKNOWN,
} from '../../../utils/app.constant';
import { FrameService } from './frame.service';

export class DeviceService {
    static async sendRegistrationRequest(data: any, postRequest = HttpService.post, reduxStore = store) {
        try {
            const response = await postRequest(data, DEVICE_REGISTRATIONS_URL);
            if (response.status === 200 || response.status === 404) {
                DeviceService.handleResponse(response, reduxStore);
            } else {
                console.error(`sendRegistrationRequest::error during request: ${response.status}, ${response.message}`);
                DeviceService.handleError(reduxStore, REGISTRATION_FAIL_UNKNOWN);
            }
        } catch (e) {
            console.error(`sendRegistrationRequest::unknown error: ${e}`);
            DeviceService.handleError(reduxStore, REGISTRATION_FAIL_UNKNOWN);
        }
    }

    private static handleError(reduxStore: any, errorMessage: string) {
        reduxStore.dispatch(deviceRegistrationFailedAction(errorMessage));
        FrameService.sendRegistrationFailedToParent();
    }

    private static handleResponse(response: any, reduxStore: any) {
        if (response.status === 404) {
            console.warn('sendRegistrationRequest::received 404');
            DeviceService.handleError(reduxStore, REGISTRATION_FAIL_EMPTY_RESULT);
        } else {
            if (selectRegistrationError(reduxStore.getState()) !== '') {
                reduxStore.dispatch(resetErrorKeyAction());
            }
            FrameService.sendDeviceIdsToParent(response.body);
        }
    }
}
