import { MessageType } from './message-type.enum';
import { IAction } from '../models/action.model';

export class MessageUtils {
    static generateMessageWithType(type: MessageType, payload: any): IAction {
        return {
            type,
            payload,
        };
    }
}
