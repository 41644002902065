import { MessageType } from '../../../utils/message-type.enum';

export class MockFrameCallsService {
    static LOCAL_ORIGIN = `${window.location.origin}/`;

    static mockParentSubmitCall() {
        MockFrameCallsService.postMessage(MessageType.SUBMIT_MESSAGE_TYPE, { accountId: 'we-dont-need-it' });
    }

    private static mockParentVinCall() {
        MockFrameCallsService.postMessage(MessageType.INITIAL_MESSAGE_TYPE, { vin: '1HGEJ8241TL007125' });
    }

    static startMockCalls() {
        // tslint:disable-next-line:no-console
        console.log('using IFrame mock calls...');
        setTimeout(MockFrameCallsService.mockParentVinCall, 2000);
    }

    private static postMessage(type: MessageType, payload: any): void {
        window.parent.postMessage({ type, payload }, MockFrameCallsService.LOCAL_ORIGIN);
    }
}
