import { IAction } from '../../models/action.model';
import { USER_PROFILE_OBTAINED } from './login.actions';

const initialState = {
    errorMessage: '',
    hasUserInfo: false,
    isLoggedIn: false,
    showError: false,
    userInfo: null,
};

const sessionReducer = (state = initialState, action: IAction = {} as IAction) => {
    switch (action.type) {
        case USER_PROFILE_OBTAINED: {
            const profile = action.payload;
            return {
                ...state,
                hasUserInfo: true,
                isLoggedIn: true,
                userInfo: {
                    email: profile.email,
                    firstName: profile.givenName,
                    lastName: profile.familyName,
                    roles: [],
                },
            };
        }
        default:
            return state;
    }
};

export default sessionReducer;
