import { trace } from '../../configuration/trace';
import { UserManager } from 'oidc-client';
import { routeStorage } from './storage';
import { env } from '../../env';

const param = (window: Window, regex: RegExp, defaultValue = null) => {
    let result = defaultValue;
    decodeURI(window.location.href).replace(regex, (substring, it) => {
        result = it;
        return substring;
    });
    return result;
};

const saveCurrentRoute = () => {
    const initialRoute = '/' + window.location.hash;

    routeStorage.saveRoute(initialRoute);

    trace('saving initial route', initialRoute);
};

export const attemptInitialSignIn = async (userManager: UserManager) => {
    const isFreshRedirect = Boolean(param(window, /.*code=([^&]+)/u));

    try {
        const user = await userManager.signinSilent();
        const initialRoute = routeStorage.getRoute();

        trace('initialRoute lookup', initialRoute);

        if (initialRoute && isFreshRedirect) {
            trace(`Go to location "/${initialRoute}"`);
            window.location.replace(`#/${initialRoute}`);
        }

        routeStorage.discardRoute();
        return user;
    } catch (error) {
        trace('oidc.signinSilent failed, trying page redirect...', error);

        if (env.runtimeConfig.login.preventRedirect) {
            // eslint-disable-next-line no-console
            console.warn('[feature/login] redirect prevented due to config. Error was', error);
        } else if (isFreshRedirect) {
            trace('oidc.signinSilent.error', 'redirect prevented due to supsicious signin error', error);
            routeStorage.discardRoute();
        } else {
            saveCurrentRoute();
            await userManager.signinRedirect();
        }

        trace('auth problem?', error);
        throw new Error('Need to sign in');
    }
};
