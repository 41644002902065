import get from 'lodash/fp/get';
import merge from 'lodash/fp/merge';

import { localIntegrationConfig } from './env/env.local_integration';
import { localConfig } from './env/env.local';
import { productionConfig } from './env/env.production';
import { testConfig } from './env/env.testing';

export const getConfig = get('config');

export const getLoginClientId = get('config.login.clientId');

export const getLoginRedirectUri = get('config.login.redirectUri');

export const getUserSettingsBackend = get('config.backend.USER_SETTINGS_SERVICE');

export const getAssetAdministration = get('config.backend.ASSET_ADMINISTRATION');

export const getWebfleetRegistryBackend = get('config.backend.WEBFLEET_REGISTRY_SERVICE');

const prodHost = 'webfleet-registry-web.webfleet-connector.rio.cloud';

export const determineFeatureToggles = (window: Window) => {
    const param = (regex: any, defaultValue = null) => {
        let result = defaultValue;
        // @ts-ignore
        decodeURI(window.location?.href).replace(regex, (_, it) => {
            result = it;
        });
        return result;
    };

    const detectToggle = (transform: any, name: string, defaultValue = null) => {
        // Note that IE11 throws a SyntaxError when using 'u' here
        // eslint-disable-next-line require-unicode-regexp
        let value = param(new RegExp(`${name}=([^&]+)`));

        if (value) {
            try {
                window.localStorage.setItem(name, value);
            } catch (_) {
                // Intentionally left blank
            }
        } else {
            try {
                // @ts-ignore
                value = window.localStorage.getItem(name);
            } catch (_) {
                // Intentionally left blank
            }
        }

        return value ? transform(value) : defaultValue;
    };

    const BooleanToggle = (value: string) => {
        return value === 'true' || value === '1';
    };

    // ... add your custom feature toggles here
    return {
        enforcedEnv: detectToggle(String, 'ft_env'),
        enforcedLocale: detectToggle(String, 'ft_enforceLocale'),
        mockedToken: detectToggle(String, 'ft_mockToken'),
        tracing: detectToggle(BooleanToggle, 'ft_tracing'),
    };
};

export const getRuntimeConfig = (window: any, environment: any) => {
    const defaultConfig = {
        backend: {},
        homeRoute: `${window.location?.origin}`,
        id: 'env.stub',
        login: {
            clientId: 'bcff788b-0134-41f1-ad55-fbc749dad3ac',
            oauthScope: ['openid', 'profile', 'email', 'webfleet-connector.write', 'device.write'],
            mockLocale: 'en-GB',
            preventRedirect: false,
        },
        // TODO: Check necessity to use sentry
        sentryToken: '<you-need-to-obtain-your-own-sentry-token>',
    };

    let config = localConfig;

    if (environment.isRunningOnProd || environment.isProdBuild) {
        // @ts-ignore
        config = productionConfig;
    }

    if (environment.isIntegrationEnv) {
        // @ts-ignore
        config = localIntegrationConfig;
    }

    if (environment.isTestEnv) {
        // @ts-ignore
        config = testConfig;
    }

    return merge(merge({}, defaultConfig), config);
};

export const configureEnv = (window: Window, processEnv: any): any => {
    const host = window.location?.host;
    const { NODE_ENV, REACT_APP_ENV } = processEnv;

    const isProdEnv = host === prodHost;
    const isTestEnv = NODE_ENV === 'test';
    const isLocalEnv = !isProdEnv && !isTestEnv;
    const isIntegrationEnv = REACT_APP_ENV === 'integration';

    const shouldSendMetrics = isProdEnv;

    const isProdBuild = NODE_ENV === 'production';
    const isRunningOnProd = host === prodHost;
    const runsInSandbox = isProdBuild && !isRunningOnProd;

    // "Real" old IE - as in: not Edge in IE11 mode - is quite iffy about
    // accessing the console when the developer tools are not visible
    // so we will be extra defensive here, just to be safe
    // @ts-ignore
    const console = typeof window.console !== 'undefined' ? window.console : null;

    // IE... I'm looking at you <o.O>
    const hasConsole = !!console;

    const shouldRestrictLog = NODE_ENV === 'production';
    const shouldUseConsoleLogger = false; // NODE_ENV !== 'test' && !shouldRestrictLog;

    const featureToggles = determineFeatureToggles(window);

    // tslint:disable-next-line:no-shadowed-variable
    const env = {
        featureToggles,
        hasConsole,
        isLocalEnv,
        isProdBuild,
        isIntegrationEnv,
        isRunningOnProd,
        isTestEnv,
        runsInSandbox,
        shouldRestrictLog,
        shouldUseConsoleLogger,
        shouldSendMetrics,
        runtimeConfig: {},
    };

    env.runtimeConfig = getRuntimeConfig(window, env);

    return env;
};

export const env = configureEnv(window, process.env);

if (!env.hasConsole && window) {
    // I'm still looking at you IE <o.O>
    const noop = () => {};

    const fakeConsole = {
        debug: noop,
        info: noop,
        isFakeConsole: true,
        log: noop,
        warn: noop,
    };

    // @ts-ignore
    window.console = fakeConsole;
}

if (env.featureToggles.tracing) {
    // tslint:disable-next-line:no-console
    console.log(env);
}
