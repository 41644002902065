import { Dialog } from 'rio-uikit';
import { MockFrameCallsService } from '../services/mock-frame-calls.service';

const footer = (
    <div>
        <button type="button" className="float-left btn btn-default" onClick={() => 'back click handler'}>
            <span className="rioglyph rioglyph-chevron-left" aria-hidden="true" />
            {'Back'}
        </button>
        <div className="float-right btn-toolbar">
            <button
                type="button"
                className={'btn btn-primary float-right btn-icon-right'}
                onClick={MockFrameCallsService.mockParentSubmitCall}
                disabled={false}
            >
                <span className="rioglyph rioglyph-chevron-right" aria-hidden="true" />
                {'Next'}
            </button>
        </div>
    </div>
);

export const WithDialog = (props: any) => {
    return (
        <Dialog
            show={true}
            title={'Create Device'}
            body={props.body}
            footer={footer}
            bodyClassName="padding-0"
            bsSize={'SIZE_MD'}
            onHide={() => 'on close'}
            showCloseButton={true}
        />
    );
};
