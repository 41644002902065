import cond from 'lodash/fp/cond';
import constant from 'lodash/fp/constant';
import matches from 'lodash/fp/matches';
import noop from 'lodash/fp/noop';
import otherwise from 'lodash/fp/stubTrue';

import { configureEnv } from '../env';

// @ts-ignore
// eslint-disable-next-line
export function configureReporting(processEnv: any, window = window) {
    const { Raven } = window;
    const {
        isProdBuild,
        isRunningOnProd,
        // @ts-ignore
        isRunningOnStaging,
        runsInSandbox,
    } = configureEnv(window, processEnv || process.env);

    // "Real" old IE - as in: not Edge in IE11 mode - is quite iffy about
    // accessing the console when the developer tools are not visible
    // so we will be extra defensive here, just to be safe
    const console = typeof window.console !== 'undefined' ? window.console : null;

    // IE... I'm looking at you <o.O>
    const hasConsole = !!console;

    const justLogException = hasConsole
        ? (e: { message: string | number | boolean }) => {
              console.error('Exception captured:', e);

              const query = encodeURIComponent(e.message);
              console.info('Let me StackOverflow that for you:', `https://stackoverflow.com/search?q=${query}`);
              console.info('Let me Google that for you:', `https://google.com/search?q=${query}`);
          }
        : noop;

    const reportWithSentry = Raven ? (...args: any) => Raven.captureException(...args) : noop;

    const logToConsoleAndReport = (e: { message: string | number | boolean }) => {
        justLogException(e);
        reportWithSentry(e);
    };

    const state = {
        isProdBuild,
        isRunningOnProd,
        isRunningOnStaging,
        runsInSandbox,
    };

    const captureException = cond([
        [matches({ isRunningOnProd: true }), constant(reportWithSentry)],
        [matches({ isRunningOnStaging: true }), constant(logToConsoleAndReport)],
        [matches({ runsInSandbox: true }), constant(logToConsoleAndReport)],
        [otherwise, constant(justLogException)],
    ])(state);

    const captureSagaCancelledMiddleware = (/* store */) => (next: (val: object) => any) =>
        cond([
            // @ts-ignore
            [matches({ type: 'report/SAGA_CANCELLED' }), ({ payload }) => captureException(payload)],
            [otherwise, next],
        ]);

    return {
        captureException,
        captureSagaCancelledMiddleware,
    };
}
