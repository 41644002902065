import { IAction } from '../../models/action.model';
import { accessToken } from './access-token';
import { ACCESS_TOKEN_STORED, ID_TOKEN_STORED } from './token-handling.actions';

const initialState = {
    accessToken: accessToken.getAccessToken(),
    idToken: null,
};

const reducer = (state = initialState, action: IAction = {} as IAction) => {
    switch (action.type) {
        case ACCESS_TOKEN_STORED:
            return {
                ...state,
                accessToken: action.payload,
            };
        case ID_TOKEN_STORED:
            return {
                ...state,
                idToken: action.payload,
            };
        default:
            return state;
    }
};

export default reducer;
