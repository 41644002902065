import { store } from '../configuration/store';
import { getAssetAdministration } from '../env';

export enum MessageType {
    // implicit message type of 'window.post{...}'
    PARENT_MESSAGE_TYPE = 'message',
    // sent when the device-registry window is loaded - contains the VIN in the payload
    INITIAL_MESSAGE_TYPE = 'E_TGE_DEVICE_REGISTRATION_DATA_ENTERED',
    // sent when the user clicks on 'save' in the parent dialog
    SUBMIT_MESSAGE_TYPE = 'USER_DATA_SUBMITTED',
    // sent on device registration success
    SUCCESS_MESSAGE_TYPE = 'CONNECTOR_DEVICE_REGISTRATION_SUCCESS',
    // sent on device registration failure
    ERROR_MESSAGE_TYPE = 'CONNECTOR_DEVICE_REGISTRATION_ERROR',
    // sent when the Dialog body resizes
    CONNECTOR_SPA_RESIZE_MESSAGE_TYPE = 'CONNECTOR_SPA_RESIZE',
    // sent when the listeners are ready
    CONNECTOR_SPA_READY = 'CONNECTOR_SPA_READY',
}

export const ALLOWED_PARENT_ORIGIN = `${getAssetAdministration(store.getState())}/`;
