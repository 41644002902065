import get from 'lodash/fp/get';
import {
    VIN_RECEIVED_ACTION,
    MILEAGE_UPDATED_ACTION,
    DEVICE_REGISTRATION_FAILED_ACTION,
    MILEAGE_VALIDATION_ACTION,
    RESET_ERROR_KEY_ACTION,
} from '../actions/device.actions';
import { IAction } from '../../../models/action.model';

export const initialState = {
    vin: '',
    mileage: '',
    isMileageValid: true,
    errorKey: '',
};

const getUpdatedState = (state: any, key: string, value: any) => ({
    ...state,
    [key]: value,
});

const registrationReducer = (state = initialState, action: IAction = {} as IAction) => {
    switch (action.type) {
        case VIN_RECEIVED_ACTION:
            return getUpdatedState(state, 'vin', action.payload);
        case MILEAGE_UPDATED_ACTION:
            return getUpdatedState(state, 'mileage', action.payload);
        case DEVICE_REGISTRATION_FAILED_ACTION:
            return getUpdatedState(state, 'errorKey', action.payload);
        case MILEAGE_VALIDATION_ACTION:
            return getUpdatedState(state, 'isMileageValid', action.payload);
        case RESET_ERROR_KEY_ACTION:
            return getUpdatedState(state, 'errorKey', '');
        default:
            return state;
    }
};

export const selectVin = get('registrations.vin');
export const selectMileage = get('registrations.mileage');
export const selectRegistrationError = get('registrations.errorKey');
export const selectIsMileageValid = get('registrations.isMileageValid');

export default registrationReducer;
