import getOr from 'lodash/fp/getOr';
import { store } from '../../../configuration/store';
import { mileageValidationAction, resetErrorKeyAction, vinReceivedAction } from '../actions/device.actions';
import { DeviceService } from './device.service';
import { selectMileage, selectRegistrationError, selectVin } from '../reducers/registration.reducer';
import { MileageValidator } from '../validators/mileage.validator';
import { ALLOWED_PARENT_ORIGIN, MessageType } from '../../../utils/message-type.enum';
import { MessageUtils } from '../../../utils/message-utils';
import { IMessage } from '../../../models/message.model';
import { AppAction, ReduxStore } from '../../../utils/app.types';

export class FrameService {
    static INCOMING_MESSAGE_TYPES = [MessageType.INITIAL_MESSAGE_TYPE, MessageType.SUBMIT_MESSAGE_TYPE];

    static async receiveParentData(
        message: IMessage,
        reduxStore: ReduxStore = store,
        registerDevice: Function = DeviceService.sendRegistrationRequest
    ): Promise<void> {
        if (!FrameService.isValidParentMessage(message)) {
            return;
        }

        try {
            switch (message.data?.type) {
                case MessageType.INITIAL_MESSAGE_TYPE:
                    FrameService.handleInitialMessage(message, 'data.payload.vin', vinReceivedAction, reduxStore);
                    break;
                case MessageType.SUBMIT_MESSAGE_TYPE:
                    await FrameService.handleSubmitMessage(registerDevice, reduxStore);
                    break;
                default:
                    throw new Error(`receiveParentData::unknown message type:${message.data?.type}`);
            }
        } catch (e) {
            console.error(`receiveParentData::${e}`);
        }
    }

    static registerMessageListener(): void {
        window.addEventListener(MessageType.PARENT_MESSAGE_TYPE, FrameService.receiveParentData);
    }

    static sendSpaReadyToParent(): void {
        const messageWithType = MessageUtils.generateMessageWithType(MessageType.CONNECTOR_SPA_READY, {});
        window.parent.postMessage(messageWithType, ALLOWED_PARENT_ORIGIN);
    }

    static sendDeviceIdsToParent(deviceIds: Array<string>): void {
        const messageWithType = MessageUtils.generateMessageWithType(MessageType.SUCCESS_MESSAGE_TYPE, deviceIds);
        window.parent.postMessage(messageWithType, ALLOWED_PARENT_ORIGIN);
    }

    static sendHeightToParent(height: string): void {
        const messageWithType = MessageUtils.generateMessageWithType(MessageType.CONNECTOR_SPA_RESIZE_MESSAGE_TYPE, {
            height,
        });
        window.parent.postMessage(messageWithType, ALLOWED_PARENT_ORIGIN);
    }

    static sendRegistrationFailedToParent(): void {
        const messageWithType = MessageUtils.generateMessageWithType(MessageType.ERROR_MESSAGE_TYPE, {});
        window.parent.postMessage(messageWithType, ALLOWED_PARENT_ORIGIN);
    }

    private static async handleSubmitMessage(registerDevice: Function, reduxStore: ReduxStore): Promise<void> {
        if (selectRegistrationError(reduxStore.getState()) !== '') {
            reduxStore.dispatch(resetErrorKeyAction());
        }
        const mileage = selectMileage(reduxStore.getState());
        const vin = selectVin(reduxStore.getState());
        if (mileage !== '' && MileageValidator.validateMileage(mileage)) {
            await registerDevice({ vin, mileage });
        } else {
            reduxStore.dispatch(mileageValidationAction(false));
            FrameService.sendRegistrationFailedToParent();
        }
    }

    private static handleInitialMessage(
        message: IMessage,
        path: string,
        action: AppAction,
        reduxStore: ReduxStore = store
    ): void {
        const content = getOr(null, path, message);
        if (!content) {
            throw new Error(`${path} not found in message:${JSON.stringify(message)}`);
        }
        reduxStore.dispatch(action(content));
    }

    private static isValidParentMessage(message: IMessage): boolean {
        // message.data.type belongs to the contract with the parent IFrame
        const messageType = getOr(null, 'data.type', message);
        // message.type is the implicit message type of 'window.post{...}'
        return (
            message.type === MessageType.PARENT_MESSAGE_TYPE &&
            FrameService.INCOMING_MESSAGE_TYPES.includes(messageType) &&
            FrameService.getOrigin() === ALLOWED_PARENT_ORIGIN
        );
    }

    private static getOrigin(): string {
        return window.location !== window.parent.location ? document.referrer : document.location.href;
    }
}
