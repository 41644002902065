import { combineReducers } from 'redux';

import configReducer from './config.reducer';
import langReducer from '../features/lang/lang.reducer';
import loginReducer from '../features/login/login.reducer';
import tokenHandlingReducer from '../features/token-handling/token-handling.reducer';
import sessionReducer from '../features/login/session.reducer';
import registrationReducer from '../features/app/reducers/registration.reducer';

export const rootReducer = combineReducers({
    config: configReducer,
    lang: langReducer,
    login: loginReducer,
    session: sessionReducer,
    tokenHandling: tokenHandlingReducer,
    registrations: registrationReducer,
});
