import cloneDeep from 'lodash/fp/cloneDeep';
import { IAction } from '../models/action.model';
import { env } from '../env';

const initial = cloneDeep(env.runtimeConfig);

const configReducer = (state = initial, action: IAction) => {
    switch (action.type) {
        default:
            return state;
    }
};

export default configReducer;
