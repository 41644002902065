// This is the runtime configuration being used for local development
// the iframe-SPA is loaded in a "test" dialog
// authorization, WEBFLEET_REGISTRY_SERVICE and ASSET_ADMINISTRATION are mocked

export const localConfig = {
    backend: {
        APP_REGISTRY: `${window.location.origin}/appregistry`,
        USERADMIN_SERVICE: `${window.location.origin}/usersettings`,
        USER_SETTINGS_SERVICE: `${window.location.origin}/usersettings`,
        WEBFLEET_REGISTRY_SERVICE: `http://localhost:9090`, // address of our mock express server [/server.js]
        ASSET_ADMINISTRATION: `${window.location.origin}`, // origin of SPA parent, in this case our webpack server
    },
    homeRoute: `${window.location.origin}`,
    id: 'env.local',
    login: {
        authority: `https://localhost:8443`,
        clientId: 'client1',
        mockAuthorization: true,
        preventRedirect: true,
        redirectUri: `${window.location.origin}/redirect.html`,
        silentRedirectUri: `${window.location.origin}/redirect.html`,
    },
    logoutUri: `https://localhost:8443/logout`,
};
