import cloneDeep from 'lodash/fp/cloneDeep';
import merge from 'lodash/fp/merge';
import { accessToken } from '../../token-handling/access-token';

interface HttpOptions {
    method: 'POST' | 'GET';
    body?: any;
    mode?: 'cors';
}

export class HttpService {
    static post(data: any, url: string, fetch = window.fetch) {
        return HttpService.authorizeRequest(
            url,
            {
                method: 'POST',
                body: JSON.stringify(data),
                mode: 'cors',
            },
            fetch
        ).then((resp) =>
            resp.json().then((body: any) => Promise.resolve({ status: resp.status, message: resp.statusText, body }))
        );
    }

    static get(url: any, fetch = window.fetch) {
        return HttpService.authorizeRequest(url, { method: 'GET' }, fetch).then((resp) => resp.json());
    }

    /**
     * @param url
     * @param options
     * @param fetch - param added to facilitate mocking it in jest tests: always window.fetch when not mocked
     * @private
     */
    private static authorizeRequest(url: string, options: HttpOptions, fetch: Function): Promise<any> {
        return fetch(
            url,
            merge(cloneDeep(options), {
                headers: {
                    Authorization: `Bearer ${accessToken.getAccessToken()}`,
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                },
            })
        );
    }
}
