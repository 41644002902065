import join from 'lodash/fp/join';

import { env, configureEnv } from '../../env';

import { InMemoryWebStorage, WebStorageStateStore, UserManager, User } from 'oidc-client';
import { ILoginInfo, LoginInfo, MockUser, MockUserManager, OauthConfig } from './login.model';
import { trace } from '../../configuration/trace';

export class LoginService {
    private static RETRY_SIGNIN_TIMEOUT_IN_MS = 30000;

    static configureAuth(window: Window, processEnv: NodeJS.ProcessEnv): UserManager {
        const { runtimeConfig } = configureEnv(window, processEnv);

        const redirectUri = runtimeConfig.login.redirectUri;
        const silentRedirectUri = runtimeConfig.login.silentRedirectUri;

        const settings = {
            authority: `${runtimeConfig.login.authority}`,
            client_id: `${runtimeConfig.login.clientId}`,
            loadUserInfo: false,
            redirect_uri: `${redirectUri}`,
            response_type: 'code',
            scope: join(' ', runtimeConfig.login.oauthScope),
            silent_redirect_uri: `${silentRedirectUri || redirectUri}`,
            userStore: new WebStorageStateStore({ store: new InMemoryWebStorage() }),
        };

        trace('oidc.auth.settings', settings);

        return new UserManager(settings);
    }

    static mockOAuth(oAuthConfig: OauthConfig): MockUserManager {
        console.warn('[feature/login/oidc-session] Using mocked authorization due to config setting');

        return {
            // @ts-ignore
            signinSilent: () => {
                oAuthConfig.onSessionRenewed(
                    LoginService.adaptPublishedInfo({
                        access_token: 'valid-mocked-oauth-bogus-token',
                        expires_in: 60 * 60 * 24 * 365,
                        profile: {
                            account: 'mockaccount',
                            azp: 'test-client',
                            email: 'test@example.com',
                            family_name: 'Client',
                            given_name: 'Test',
                            locale: env.runtimeConfig.login.mockLocale,
                            name: 'Test Client',
                            sub: 'prod-rio-users:mock-user',
                        },
                    })
                );
                return Promise.resolve();
            },
            clearStaleState: () => {
                console.info('[feature/login/oidc-session] Stale state cleared');
                return Promise.resolve();
            },
            signinRedirect: () => Promise.resolve(),
        };
    }

    static configureUserManager(oauthConfig: OauthConfig, userManager: UserManager): UserManager {
        userManager.events.addUserLoaded((user) => {
            oauthConfig.onSessionRenewed(LoginService.adaptPublishedInfo(user));
        });

        userManager.events.addUserUnloaded(() => {
            oauthConfig.onSessionExpired();
        });

        userManager.events.addSilentRenewError(() => {
            LoginService.retrySignInSilent(oauthConfig, userManager);
        });

        userManager.events.addUserSignedOut((...args) => {
            oauthConfig.onSessionExpired();
        });

        return userManager;
    }

    private static retrySignInSilent(oauthConfig: OauthConfig, userManager: UserManager): void {
        userManager.signinSilent().catch((error) => {
            if (error.message === 'login_required') {
                oauthConfig.onSessionExpired();
            } else {
                setTimeout(
                    () => LoginService.retrySignInSilent(oauthConfig, userManager),
                    LoginService.RETRY_SIGNIN_TIMEOUT_IN_MS
                );
            }
        });
    }

    private static adaptPublishedInfo(user: User | MockUser): ILoginInfo {
        return new LoginInfo(user.access_token, user.expires_in, user.profile, user.profile.locale);
    }
}
