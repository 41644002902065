import { createHashHistory } from 'history';
import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { rootReducer } from './reducers';

export const history = createHashHistory({
    hashType: 'noslash',
});

const middleware: Array<any> = [
    /* ... */
];

const createStoreWithMiddleware = composeWithDevTools(applyMiddleware(...middleware))(createStore);

export const store = createStoreWithMiddleware(rootReducer);
