import getOr from 'lodash/fp/getOr';

import { changeLocale, languageDataFetched } from './lang.actions';
import { getSupportedLocale as defaultGetSupportedLocale } from './lang.selectors';
import { configureReporting } from '../../configuration/error-reporting';
import { LanguageData, ReduxStore } from '../../utils/app.types';

export class LangService {
    static MODULE_NAME = 'webfleet-registry-web';

    static configureFetchLanguageData = (
        store: ReduxStore,
        fetchLanguageData = LangService.getLanguageData,
        getSupportedLocale = defaultGetSupportedLocale
    ) => (locale?: string) => {
        if (!locale) {
            console.warn('No "locale" supplied when fetching language data!');
            return Promise.reject(new Error('no locale'));
        }
        store.dispatch(changeLocale(locale));
        const supportedLocale = getSupportedLocale(store.getState());
        return fetchLanguageData(supportedLocale)
            .then((languageData: LanguageData) => {
                store.dispatch(languageDataFetched(supportedLocale, languageData));
            })
            .catch((error: Error) => {
                LangService.sendError(error);
                throw error;
            });
    };

    private static async getLanguageData(locale: string): Promise<void | Error> {
        return import(/* webpackChunkName: "[request]" */ `./translations/${locale}.json`)
            .then(LangService.normalizeDynamicImport)
            .catch((error: Error) => {
                LangService.sendError(error);
                return error;
            });
    }

    private static normalizeDynamicImport(imported: LanguageData) {
        getOr(imported, 'default', imported);
    }

    private static sendError(exception: Error) {
        configureReporting(exception, {
            tags: {
                module: LangService.MODULE_NAME,
            },
        });
    }
}
