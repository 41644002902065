import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React from 'react';
import ReactDOM from 'react-dom';
import { Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import './index.css';

import { userProfileObtained, userSessionExpired, userSessionRenewed } from './features/login/login.actions';
import { accessToken } from './features/token-handling/access-token';
import { accessTokenStored, idTokenStored } from './features/token-handling/token-handling.actions';

import { env } from './env';

import DeviceRegistry from './features/app/containers/DeviceRegistry';
import { WithDialog } from './features/app/components/DeviceDialog';
import handleLoginRedirect from './features/login/redirect';
import { store, history } from './configuration/store';
import { MockFrameCallsService } from './features/app/services/mock-frame-calls.service';
import { OauthConfig } from './features/login/login.model';
import { attemptInitialSignIn } from './features/login/oauth';
import { trace } from './configuration/trace';
import { LoginService } from './features/login/login.service';
import { LangService } from './features/lang/lang.service';

const root = document.querySelector('#iframeRoot');

const fetchLanguageData = LangService.configureFetchLanguageData(store);

function getMainComponent() {
    return env.isLocalEnv && !env.isIntegrationEnv && !env.isProdBuild ? (
        <WithDialog body={<DeviceRegistry />} />
    ) : (
        <DeviceRegistry />
    );
}

function renderApplication() {
    ReactDOM.render(
        <Provider store={store}>
            <Router history={history}>{getMainComponent()}</Router>
        </Provider>,
        root
    );
}

function main() {
    const oauthConfig: OauthConfig = {
        onSessionExpired: () => {
            accessToken.discardAccessToken();
            store.dispatch(userSessionExpired());
        },
        onSessionRenewed: (result: any /*jwt token*/) => {
            trace('index.onTokenRenewed', result);

            accessToken.saveAccessToken(result.accessToken);
            store.dispatch(accessTokenStored(result.accessToken));
            store.dispatch(idTokenStored(result.idToken));
            store.dispatch(userProfileObtained(result.profile));

            store.dispatch(userSessionRenewed());

            // You will need to get the user language by yourself then
            // you may fetch the suitable messages. Depending
            // on when and from where you fetch the user settings you might
            // want to employ a loading spinner while the request is ongoing.
            fetchLanguageData(result.locale);
        },
    };

    const isAllowedToMockAuth = env.isLocalEnv || env.isTestEnv;
    const userManager =
        isAllowedToMockAuth && env.runtimeConfig.login.mockAuthorization
            ? LoginService.mockOAuth(oauthConfig)
            : LoginService.configureUserManager(oauthConfig, LoginService.configureAuth(window, process.env));

    if (env.isLocalEnv && !env.isIntegrationEnv) {
        MockFrameCallsService.startMockCalls();
    }

    attemptInitialSignIn(userManager)
        .then(renderApplication)
        .catch((e: Error) => console.error('could not start application', e));
}

if (window.location.href.startsWith(env.runtimeConfig.login.redirectUri as string)) {
    handleLoginRedirect();
} else {
    main();
}
